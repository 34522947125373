import React, { useState, useEffect } from 'react'

import { Container as DefaultContainer } from './styles'

import styled from 'styled-components'

import { useQuery, useMutation } from '@apollo/react-hooks'

import MyBusinessCards from './my-business-cards'

import { MY_PROFILE, DELETE_BUSINESS_CARD } from './data-provider'

import CreateBusinessCardForm from './create-business-card-form'

import { IoIosCloseCircleOutline } from 'react-icons/io'

import UseCardForEventForm from './use-card-for-event-form'

const Container = styled(DefaultContainer)`
    min-height: 70vh;
    padding-bottom: 1px;
`

const PageTitle = styled.h1`
    color: ${props => props.theme.dark};
    margin-bottom: 0.5rem;
`

const Close = styled(IoIosCloseCircleOutline)`
    font-size: 3rem;
    color: ${props => props.theme.gray1};
    display: block;
    margin-left: auto;
    cursor: pointer;
`

export default () => {
    const { data } = useQuery(MY_PROFILE)

    const [cardToEdit, setCardToEdit] = useState(null)

    const [useCardForEvent, setUseCardForEvent] = useState(null)

    const [deleteBusinessCard] = useMutation(DELETE_BUSINESS_CARD, {
        onCompleted: () => {
            if (typeof window !== 'undefined') {
                window.location.reload()
            }
        },
    })

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (!window.localStorage.token) {
                window.location = '/login'
            }
        }
    }, [])

    const businessCards =
        data && data.myProfile ? data.myProfile.businessCards : []

    return (
        <Container>
            <PageTitle>Personal area</PageTitle>
            {!cardToEdit && !useCardForEvent && (
                <MyBusinessCards
                    businessCards={businessCards}
                    onEditCard={card => {
                        delete card.user
                        delete card.__typename
                        delete card.personalPhoto
                        setCardToEdit(card)
                    }}
                    onDeleteCard={card => {
                        deleteBusinessCard({
                            variables: {
                                id: card._id,
                            },
                        })
                    }}
                    onUseForEventClick={card => {
                        setUseCardForEvent(card)
                    }}
                />
            )}
            {cardToEdit && (
                <>
                    <Close onClick={() => setCardToEdit(null)} />
                    <CreateBusinessCardForm
                        mode="edit"
                        editCard={cardToEdit}
                        onCompleted={() => setCardToEdit(null)}
                    />
                </>
            )}
            {useCardForEvent && (
                <>
                    <Close onClick={() => setUseCardForEvent(null)} />
                    <UseCardForEventForm
                        card={useCardForEvent}
                        onClose={() => setUseCardForEvent(null)}
                    />
                </>
            )}
        </Container>
    )
}
