import React from 'react'

import styled, { keyframes } from 'styled-components'

import { Link as GatsbyLink } from 'gatsby'

import { isEmpty } from 'lodash-es'

const Card = styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: 1rem;
    border: 1px solid ${props => props.theme.gray3};
    padding: 0.5rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        padding: 1rem;
    }
`

const OrganizationName = styled.div`
    flex: 1;
    font-size: 0.8rem;
`

const Name = styled.div`
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;

    margin-bottom: 0.5rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        margin-bottom: 0.5rem;
    }
`

const Actions = styled.div`
    display: flex;
    margin-top: 1rem;
`

const Title = styled.h2`
    font-weight: 400;
    font-size: 1rem;
`

const Link = styled(GatsbyLink)`
    color: ${props => props.theme.blue};
    text-decoration: none;
    margin: ${props => (props.noMargin ? 0 : '0 0.5rem 0 0')};
    font-size: ${props => (props.regular ? 1 : 0.8)}rem;
`

const UseForEvent = styled(Link)`
    margin-left: auto;
`

const Text = styled.p`
    color: ${props => props.theme.gray1};
`

const NameAndEvent = styled.div`
    display: flex;
`

const ActionEventName = styled.div`
    margin-left: 1rem;
    font-weight: 300;
    margin-left: auto;
    font-size: 0.8rem;
`

const NewAction = styled.div`
    position: relative;
    display: flex;
`

const newAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`
const New = styled.span`
    position: absolute;
    top: -0.5rem;
    right: 0;
    color: ${props => props.theme.red};
    font-size: 0.5rem;
    animation: ${newAnimation} 1s linear infinite alternate;
`

const List = ({
    businessCards,
    onEditCard,
    onDeleteCard,
    onUseForEventClick = () => {},
}) => {
    if (!businessCards.length) {
        return (
            <>
                <Text>Such an empty...</Text>
                <Text>
                    Go ahead and{' '}
                    <Link to="/create-business-card" regular={1} noMargin={1}>
                        create your first business card
                    </Link>
                </Text>
            </>
        )
    }
    return businessCards.map((card, i) => {
        return (
            <Card key={card._id}>
                <NameAndEvent>
                    <Name>{card.firstName + ' ' + card.lastName}</Name>
                </NameAndEvent>
                <OrganizationName>{card.organizationName}</OrganizationName>
                <Actions>
                    <Link to={'/bc/' + card.slug}>View</Link>
                    <Link
                        onClick={e => {
                            e.preventDefault()
                            onEditCard(card)
                        }}
                    >
                        Edit
                    </Link>
                    <Link
                        onClick={e => {
                            e.preventDefault()
                            if (
                                typeof window !== 'undefined' &&
                                window.confirm(
                                    'Are you sure? This can not be undone'
                                ) &&
                                onDeleteCard(card)
                            ) {
                            }
                        }}
                    >
                        Delete
                    </Link>
                    <NewAction>
                        <Link to={'/bc/' + card.slug + '?print=true'}>
                            Print badge
                        </Link>
                        <New>new</New>
                    </NewAction>
                    {isEmpty(card.eventName) ? (
                        <UseForEvent
                            onClick={e => {
                                e.preventDefault()
                                onUseForEventClick(card)
                            }}
                        >
                            Use for event
                        </UseForEvent>
                    ) : (
                        <ActionEventName>
                            Used in: {card.eventName}
                        </ActionEventName>
                    )}
                </Actions>
            </Card>
        )
    })
}

export default props => {
    return (
        <>
            <Title>List of your business cards</Title>
            <List {...props} />
            <Link regular={1} noMargin={1} to="/create-business-card">
                Create new card
            </Link>
        </>
    )
}
