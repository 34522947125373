import React, { useState } from 'react'

import Input from './input'

import Button from './button'

import { useMutation } from '@apollo/react-hooks'

import { CREATE_CARD_FOR_EVENT } from './data-provider'

export default ({ card, onClose }) => {
    const [eventName, setEventName] = useState('')

    const [createCardForEvent] = useMutation(CREATE_CARD_FOR_EVENT, {
        onCompleted: () => {
            onClose()
            window.location.reload()
        },
    })

    return (
        <form
            onSubmit={e => {
                e.preventDefault()
                createCardForEvent({
                    variables: { eventName, cardId: card._id },
                })
            }}
        >
            <Input
                label="Event name"
                value={eventName}
                onChange={e => setEventName(e.target.value)}
            />
            <Button>Save</Button>
        </form>
    )
}
